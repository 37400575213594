import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { PageProps } from '../../types/types'
import PageTemplate from './components/PageTemplate'

interface BlogPostTemplateProps extends PageProps {
  data: { markdownRemark }
}

const BlogPostTemplate: FC<BlogPostTemplateProps> = ({
  data: { markdownRemark },
  location,
}) => {
  const {
    title,
    description,
    cover: {
      childImageSharp: { fluid: coverImage },
    },
  } = markdownRemark.frontmatter

  const htmlAst = markdownRemark?.htmlAst

  return (
    <PageTemplate
      description={description}
      htmlAst={htmlAst}
      image={coverImage}
      isBlogPost
      pathname={location.pathname}
      title={title}
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        description
        cover {
          relativePath
          childImageSharp {
            fluid(
              maxHeight: 300
              quality: 90
              duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
            ) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`
